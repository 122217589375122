<template>
  <Modal :id="'modal_' + contrat.id" :title="contrat.contrat_libelle + ' version ' + contrat.version" btn="Ouvrir">
    <template v-slot:title>
      <div class="p-1 rounded d-flex flex-row align-items-center flex-grow-1" v-background-2>
        <div class="d-flex flex-column flex-md-row w-100 p-2">
          <span class="text-muted mr-2"> Version {{ num }} </span>
          <span class="mr-1" v-if="contrat.contrat_libelle">
            {{ contrat.contrat_libelle }}
          </span>
          <span v-if="contrat.date_effet"> à partir du {{ dateSimple(contrat.date_effet) }} </span>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="w-100 pt-2 pl-3 pr-3 pl-lg-5 pr-lg-5">
        <div v-background-2 class="d-flex justify-content-between p-3 rounded">
          <div
            @click="tab = 1"
            class="cursor-pointer"
            :class="{ 'text-primary border-bottom border-primary': tab === 1 }"
          >
            Informations
          </div>
          <div
            @click="tab = 2"
            class="cursor-pointer"
            :class="{ 'text-primary border-bottom border-primary': tab === 2 }"
          >
            Paramètres
          </div>
          <div
            @click="tab = 3"
            class="cursor-pointer"
            :class="{ 'text-primary border-bottom border-primary': tab === 3 }"
          >
            Avantages
          </div>
        </div>
      </div>

      <base-form
        class="pl-lg-5 pr-lg-5 rounded"
        deleteBtn
        :loading="loading"
        :statut="statut"
        @click="updateContrat(contrat)"
        @delete="deleteContrat(contrat)"
      >
        <div v-if="tab == 1">
          <base-form-row row>
            <BaseInputDatePicker
              class
              v-model="contrat.date_effet"
              inputText="Date d'effet"
              :errors="feedback.date_effet"
              :iconActive="true"
            ></BaseInputDatePicker>
            <BaseSelect
              inputText="Type"
              v-model.number="contrat.contrat_type_id"
              :options="data.contratTypes"
              :errors="feedback.contrat_type_id"
            ></BaseSelect>
          </base-form-row>

          <div class="mt-3 border rounded p-3" v-if="contrat.contrat_type_id === 2">
            <div>Motif de recours au CDD</div>
            <Checkbox
              id="cdd_activite"
              class="w-50 pt-3"
              v-model="contrat.cdd_activite"
              text="Accroissement temporaire d'activité"
              :errors="feedback.cdd_activite"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              id="cdd_remplacement"
              class="w-50"
              v-model="contrat.cdd_remplacement"
              text="Remplacement d'un salarié"
              :errors="feedback.cdd_remplacement"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              v-show="false"
              id="cdd_alternance"
              class="w-50"
              v-model="contrat.cdd_alternance"
              text="Alternance"
              :errors="feedback.cdd_alternance"
              :iconActive="true"
            ></Checkbox>
          </div>

          <base-form-row>
            <BaseInput
              v-model="contrat.contrat_libelle"
              inputText="Libellé"
              :errors="feedback.contrat_libelle"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseSelect
              class
              inputText="Famille de métier"
              v-model.number="contrat.famille_id"
              :options="data.contratVersionsFamilles"
              :errors="feedback.famille_id"
            ></BaseSelect>
          </base-form-row>
          <base-form-row row>
            <BaseSelect
              class
              inputText="N°Siret"
              v-model.number="contrat.contrat_siret_id"
              :options="data.etablissements"
              :errors="feedback.contrat_siret_id"
            ></BaseSelect>
            <BaseSelect
              class
              inputText="Statut"
              v-model.number="contrat.contrat_statut_id"
              :options="data.contratStatus"
              :errors="feedback.contrat_statut_id"
            ></BaseSelect>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="contrat.contrat_code_comptable"
              inputText="Code comptable"
              :errors="feedback.contrat_code_comptable"
              :iconActive="true"
            ></BaseInput>
            <BaseInput
              class
              v-model="contrat.contrat_temps_travail"
              inputText="Temps de travail"
              :errors="feedback.contrat_temps_travail"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <BaseInput
              class
              v-model="contrat.contrat_position"
              inputText="Position"
              :errors="feedback.contrat_position"
              :iconActive="true"
            ></BaseInput>
            <BaseInput
              class
              v-model="contrat.contrat_coef"
              inputText="Coefficient"
              :errors="feedback.contrat_coef"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>

          <base-form-row row>
            <BaseInput
              class
              v-model="contrat.contrat_brut_annuel"
              inputText="Brut annuel"
              :errors="feedback.contrat_brut_annuel"
              :iconActive="true"
              @input="calcBaseMensuelle"
            ></BaseInput>
            <BaseInput
              class
              v-model="contrat.contrat_base_mensuelle"
              inputText="Base mensuelle"
              :errors="feedback.contrat_base_mensuelle"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>

          <base-form-row row>
            <BaseInput
              class
              v-model="contrat.contrat_taux_horaire"
              inputText="Taux horaire"
              :errors="feedback.contrat_taux_horaire"
              :iconActive="true"
            ></BaseInput>
            <BaseInput
              class
              v-model="contrat.contrat_mention_spe"
              inputText="Mention spéciale"
              :errors="feedback.contrat_mention_spe"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row class="mb-3">
            <div class="w-100">
              <Checkbox
                id="1"
                class="mt-1"
                v-model="contrat.contrat_rem_variable"
                text="Rémunération variable ?"
                :errors="feedback.contrat_rem_variable"
                :iconActive="true"
              ></Checkbox>
              <Checkbox
                id="1"
                class=""
                v-model="contrat.promotion"
                text="Promotion ?"
                :errors="feedback.promotion"
                :iconActive="true"
              ></Checkbox>
            </div>
            <BaseInput
              class
              v-model="contrat.augmentation"
              inputText="Augmentation (%)"
              :errors="feedback.augmentation"
              :iconActive="true"
              inputType="number"
              step="0.01"
            ></BaseInput>
          </base-form-row>
          <base-form-row row class="mb-3">
            <base-input-area
              v-if="contrat.promotion"
              v-model="contrat.promotion_commentaire"
              text="Commentaire (promotion)"
            ></base-input-area>
          </base-form-row>
          <div class>
            <uploader
              :id="'document_contrat_' + contrat.id"
              url="/upload/document"
              file_system="SIRH"
              :file_field="'contrats_' + contrat.id"
              :file_key="$route.params.id"
            ></uploader>
          </div>
        </div>
        <div v-if="tab == 2">
          <base-form-row class="mt-4 border p-4 rounded mb-3">
            <Checkbox
              id="1"
              class="mt-0"
              v-model="contrat.contrat_rtt"
              text="RTT"
              :errors="feedback.contrat_rtt"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              id="2"
              class="mt-1"
              v-model="contrat.contrat_temps_partiel"
              text="Temps partiel"
              :errors="feedback.contrat_temps_partiel"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              id="3"
              class="mt-1"
              v-model="contrat.contrat_virement"
              text="Virement"
              :errors="feedback.contrat_virement"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              id="4"
              class="mt-1"
              v-model="contrat.contrat_bulletin_mail"
              text="Bulletin par email"
              :errors="feedback.contrat_bulletin_mail"
              :iconActive="true"
            ></Checkbox>
            <Checkbox
              id="4"
              class="mt-1"
              v-model="contrat.non_concurrence"
              text="Clause de non-concurrence"
              :errors="feedback.non_concurrence"
              :iconActive="true"
            ></Checkbox>
          </base-form-row>
        </div>
        <div v-if="tab == 3">
          <base-form-row row>
            <Checkbox
              id="4"
              class="w-50 pt-3"
              v-model="contrat.avantage_logement"
              text="Avantage logement"
              :errors="feedback.avantage_logement"
              :iconActive="true"
            ></Checkbox>
            <BaseInput
              v-if="contrat.avantage_logement"
              v-model="contrat.avantage_logement_montant"
              inputText="Montant"
              :errors="feedback.avantage_logement_montant"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <Checkbox
              id="4"
              class="w-50 pt-3"
              v-model="contrat.avantage_nourriture"
              text="Avantage nourriture"
              :errors="feedback.avantage_nourriture"
              :iconActive="true"
            ></Checkbox>
            <BaseInput
              v-if="contrat.avantage_nourriture"
              v-model="contrat.avantage_nourriture_montant"
              inputText="Montant"
              :errors="feedback.avantage_nourriture_montant"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <Checkbox
              id="4"
              class="w-50 pt-3"
              v-model="contrat.avantage_vehicule"
              text="Avantage véhicule"
              :errors="feedback.avantage_vehicule"
              :iconActive="true"
            ></Checkbox>
            <BaseInput
              v-if="contrat.avantage_vehicule"
              v-model="contrat.avantage_vehicule_montant"
              inputText="Montant"
              :errors="feedback.avantage_vehicule_montant"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
          <base-form-row row>
            <Checkbox
              id="4"
              class="w-50 pt-3"
              v-model="contrat.code_part_vehicule"
              text="Quote-part véhicule"
              :errors="feedback.code_part_vehicule"
              :iconActive="true"
            ></Checkbox>
            <BaseInput
              v-if="contrat.code_part_vehicule"
              v-model="contrat.code_part_vehicule_montant"
              inputText="montant"
              :errors="feedback.code_part_vehicule_montant"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
        </div>
      </base-form>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { mapMutations } from "vuex";
import { errHandler } from "@/helpers.js";

import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import Modal from "@/components/bases/Modal2.vue";
import date from "@/mixins/date.js";
import BaseInputArea from "@/components/bases/InputArea.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    Modal,
    BaseInput,
    BaseSelect,
    BaseInputDatePicker,
    BaseForm,
    Checkbox,
    BaseFormRow,
    BaseInputArea,
    Uploader,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      tabView: "infos",
      statut: null,
      tab: 1,
    };
  },
  mixins: [date],
  props: ["contrat", "num"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
  methods: {
    ...mapActions({
      _updateContrat: "collaborateurs/updateContratParams",
      _deleteContrat: "collaborateurs/deleteContratParams",
    }),
    ...mapMutations({
      _setContratParams: "collaborateurs/setContratParams",
    }),
    updateContrat(contrat) {
      this.feedback = {};
      this.loading = true;
      this._updateContrat(contrat)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteContrat(contrat) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteContrat(contrat)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    calcBaseMensuelle() {
      this.contrat.contrat_base_mensuelle = (this.contrat.contrat_brut_annuel / 12 / 1.0101).toFixed(2);
    },
  },
};
</script>
<style lang="css"></style>
