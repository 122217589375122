<template>
  <div class="w-100">
    <div v-if="error403">Vous n'avez pas accès aux paramètres de contrat</div>
    <div v-if="loading && !error403">Chargement...</div>
    <div class="w-100 pt-2 pl-2 pr-2 rounded" v-if="!loading && !error403">
      <div class="w-100 d-flex flex-column" v-for="(contratParam, i) in contratParamsFiltered" :key="contratParam.id">
        <ContratParamsItem class="w-100 mb-1" :contrat="contratParam" :num="i + 1"></ContratParamsItem>
      </div>
      <div class="p-3">
        <div class="cursor-pointer text-primary text-center mb-2" @click="createContratParams()" v-if="!loadingCreate">
          Ajouter une version de contrat
        </div>
        <div v-if="loadingCreate">Chargement...</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import ContratParamsItem from "@/components/collaborateurs/profil/forms/contrat/ContratParamsItem.vue";

export default {
  components: {
    ContratParamsItem,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      loadingCreate: false,
      error403: false,
      tabView: "infos",
      statut: null,
    };
  },
  props: ["contrat"],
  computed: {
    ...mapMultiRowFields("collaborateurs", ["contratParams"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    contratParamsFiltered() {
      return this.contratParams.filter((contratParam) => contratParam.contrat_id == this.contrat.id);
    },
  },
  methods: {
    ...mapActions({
      getContratParams: "collaborateurs/getContratParams",
      _createContratParams: "collaborateurs/createContratParams",
    }),
    createContratParams: function () {
      this.feedback = {};
      this.loadingCreate = true;
      this._createContratParams({ contrat_id: this.contrat.id })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingCreate = false));
    },
  },
  mounted() {
    this.loading = true;
    this.getContratParams(this.contrat.id)
      .catch((err) => {
        if (err.response.status) {
          this.error403 = true;
        }
      })
      .finally(() => (this.loading = false));
  },
};
</script>
<style lang="css"></style>
